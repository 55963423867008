import { GoogleAPI } from 'google-maps-react'
import { createConnector, SearchState as AlgoliaSearchState } from 'react-instantsearch-core'

import { ILocation } from '../types'
import { isTooBig, milesToMeters, parsePosition } from '../utils'
import { IMapRefinement } from './connectMapRefinement'

const maxMarkersToShowInitially = 10
const maxMapBoundMeters = milesToMeters(250)
const defaultBoundsMeters = milesToMeters(50) // 50 miles
const centerUS: google.maps.LatLngLiteral = { lat: 39.82, lng: -96.1 }

export interface ICurrentMapBoundsProvided {
  bounds?: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral
}

export interface ICurrentMapBoundsExposed {
  selectedLocation?: ILocation
  includeUserLocationInBounds?: boolean
  google: GoogleAPI,
}

type SearchState = AlgoliaSearchState & { mapBounds: IMapRefinement }

export const connectCurrentMapBounds = createConnector<ICurrentMapBoundsProvided, ICurrentMapBoundsExposed>({
  displayName: 'BoundsProvider',

  getProvidedProps(
    props: ICurrentMapBoundsExposed,
    searchState: SearchState,
    { results },
    metadata,
  ) {
    const {google, selectedLocation, includeUserLocationInBounds} = props
    const {
      selectedPlace,
      expandSearch,
    } = searchState.mapBounds || ({ expandSearch: false } as IMapRefinement)

    let bounds = selectedPlace && !expandSearch && isTooBig(selectedPlace) &&
      selectedPlace.geometry.viewport

    if (!bounds) {
      const userLocation = (selectedPlace && selectedPlace.geometry.location) ||
          // no current location given, see if Algolia parsed it via our IP
        (results && results.aroundLatLng && parsePosition(results.aroundLatLng))

      if (selectedLocation) {
        bounds = calculateBounds(google.maps, selectedLocation._geoloc, includeUserLocationInBounds && userLocation)
      } else if (!results || !results.hits || !results.hits.length) {
          bounds = includeUserLocationInBounds && userLocation ?
            defaultBoundsAround(google.maps, userLocation) :
            new google.maps.LatLngBounds(centerUS, centerUS)
      } else {
        const hitLocations = results.hits.map((h) => h._geoloc) as Array<google.maps.LatLng | google.maps.LatLngLiteral>
        if (includeUserLocationInBounds && userLocation) {
          // The first closest hit should come first and set the initial bounds,
          // then the location that they've chosen to search from (or current user location)
          // should expand the bounds as far as possible in that direction.
          hitLocations.splice(1, 0, userLocation)
        }
        bounds = calculateBounds(google.maps, ...hitLocations)
      }
    }

    return {
      bounds,
    }
  },
})

function calculateBounds(maps: GoogleAPI['maps'], ...hits: Array<google.maps.LatLng | google.maps.LatLngLiteral>) {
  const {LatLngBounds, LatLng} =  maps
  const { computeDistanceBetween, interpolate } = maps.geometry.spherical

  const bounds = new LatLngBounds()
  hits.slice(0, maxMarkersToShowInitially)
    .filter((h) => h)
    .forEach((geoloc) => {
      if (!bounds.isEmpty()) {
        const from = bounds.getCenter()
        const to = geoloc instanceof LatLng ? geoloc : new LatLng(geoloc.lat, geoloc.lng)
        const metersDistant = computeDistanceBetween(from, to)
        if (metersDistant > maxMapBoundMeters) {
          // This geoloc is > 500km away, so we extend the bounds along that direction
          // but not more than 500km away.
          const maxKmAwayAlongPath = interpolate(from, to, (maxMapBoundMeters) / metersDistant)
          bounds.extend(maxKmAwayAlongPath)
          return
        }
      }
      bounds.extend(geoloc)
    })

  return bounds
}

const NorthEastInRadians = 45
const SouthWestInRadians = 225

function defaultBoundsAround(maps: GoogleAPI['maps'], position: google.maps.LatLngLiteral | google.maps.LatLng) {
  const {LatLngBounds, LatLng} =  maps
  const { computeOffset } = maps.geometry.spherical

  const origin = position instanceof LatLng ? position : new LatLng(position.lat, position.lng)
  const bounds = new LatLngBounds()
  bounds.extend(origin)
  // Extend the bounds radially around the origin to the default amount
  // 45 degrees = towards NE corner, 225 = towards SW corner
  bounds.extend(computeOffset(origin, defaultBoundsMeters, NorthEastInRadians))
  bounds.extend(computeOffset(origin, defaultBoundsMeters, SouthWestInRadians))
  return bounds
}
