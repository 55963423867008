import * as React from 'react'
import { connectRefinementList, RefinementListProvided } from 'react-instantsearch-core'
import { Daynames } from './types'

class WeekdayRefinement extends React.Component<RefinementListProvided> {
  public render() {
    return (
      <div className="ais-RefinementList">
        <ul className="ais-RefinementList-list">
          {Daynames.map((wday) => {
            return this.renderItem(wday)
          })}
        </ul>
      </div>
    )
  }

  private renderItem = (wday: string) => {
    const {currentRefinement, refine} = this.props

    const item = this.props.items.find((i) => i.label == wday) || {
      isRefined: currentRefinement.indexOf(wday) >= 0,
      value: this.toggleValue(wday),
      label: wday,
    }

    const classNames = [
      'ais-RefinementList-item',
      item.isRefined && 'ais-RefinementList-item--selected',
    ].filter(truthy)

    return <li className={classNames.join(' ')} key={wday}>
              <label className="ais-RefinementList-label">
                <input className="ais-RefinementList-checkbox" type="checkbox" checked={item.isRefined}
                  onChange={() => refine(item.value)} />
                <span className="ais-RefinementList-labelText">{wday.slice(0, 3)}</span>
              </label>
            </li>
  }

  private toggleValue = (wday: string) => {
    const {currentRefinement} = this.props
    const index = currentRefinement.indexOf(wday)
    if (index >= 0) {
      const newRefinement = currentRefinement.slice()
      newRefinement.splice(index, 1)
      return newRefinement
    }

    return currentRefinement.concat(wday)
  }
}

export default connectRefinementList(WeekdayRefinement)

function truthy(x: any) {
  return !!x
}
