import * as qs from 'qs'
import * as React from 'react'
import { SearchState } from 'react-instantsearch-core'
import { InstantSearch } from 'react-instantsearch-dom'

interface IInstantSearchProps {
  apiKey?: string
  appId?: string

  indexName: string
  widgetName: string

  storeStateInQuery?: boolean

  onSearchStateChange?: (searchState: SearchState) => void
}

interface IState {
  searchState: SearchState,
  lastLocation: string
}

const DEBOUNCE_TIME = 100

const defaults: { apiKey?: string, appId?: string } = {}
if (typeof window != 'undefined' && (window as any).WCC) {
  const constants = (window as any).WCC.CONSTANTS
  defaults.apiKey = constants.ALGOLIA_SEARCH_API_KEY
  defaults.appId = constants.ALGOLIA_APP_ID
}

export default class AlgoliaSearch extends React.Component<IInstantSearchProps, IState> {
  public static defaultProps = {
    ...defaults,
  }

  /**
   * On initial load, parse out the searchState from the URL.
   */
  public static getDerivedStateFromProps(props: IInstantSearchProps, state: IState) {
    if (!props.storeStateInQuery) {
      return state
    }
    if (window.location.href === state.lastLocation) {
      return state
    }

    return {
      searchState: {
        // preserve the mapBounds which are not serialized into the URL.
        ...state.searchState,
        ...urlToSearchState(window.location),
      },
      lastLocation: window.location.href,
    }
  }

  private debouncedUpdateQuery: number | undefined

  constructor(props: IInstantSearchProps) {
    super(props)

    this.state = {
      searchState: props.storeStateInQuery && urlToSearchState(window.location),
      lastLocation: window.location.href,
    }
  }

  public render() {
    const { apiKey, appId, widgetName, storeStateInQuery, ...props } = this.props
    const { searchState } = this.state

    return (
      <InstantSearch
        apiKey={ apiKey! }
        appId={ appId! }
        root={ {Root: 'div', props: {className: widgetName}} }
        { ...props }

        // these have to be after ...props so they don't get overridden
        searchState={storeStateInQuery ? searchState : undefined}
        createURL={storeStateInQuery ? createURL : undefined}
        onSearchStateChange={this.onSearchStateChanged}
      >
        {this.props.children}
      </InstantSearch>
    )
  }

  public componentDidMount = () => {
    const {searchState} = this.state

    if (this.props.storeStateInQuery) {
      window.addEventListener('popstate', this.onPopStateHandler)
    }

    if (this.props.onSearchStateChange && searchState) {
      this.props.onSearchStateChange(searchState)
    }
  }

  public componentWillUnmount = () => {
    window.removeEventListener('popstate', this.onPopStateHandler)
  }

  /*
  * The magic happens here -
  * look at this tutorial: https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/
  */

  /**
   * Update the current URL whenever the search state changes
   */
  private onSearchStateChanged = (searchState: SearchState) => {
    clearTimeout(this.debouncedUpdateQuery)

    if (this.props.storeStateInQuery) {
      this.debouncedUpdateQuery = setTimeout(this.updateQuery, DEBOUNCE_TIME) as unknown as number
    }

    this.setState({
      searchState,
    })

    if (this.props.onSearchStateChange) {
      this.props.onSearchStateChange(searchState)
    }
  }

  private updateQuery = () => {
    const {searchState} = this.state

    const url = searchStateToUrl(window.location, searchState)
    window.history.replaceState(
      {},
      document.title,
      url,
    )

    this.setState({
      lastLocation: url,
    })
  }

  /**
   * Called when the URL was changed outside of the control of AlgoliaSearch,
   * without a page refresh.
   * ex. hitting "back" from bootstrap tabs
   */
  private onPopStateHandler = (evt: PopStateEvent) => {
    const state: unknown = evt.state
    if (!state || !isSearchState(state)) {
      return
    }

    clearTimeout(this.debouncedUpdateQuery)
    this.debouncedUpdateQuery = setTimeout(() => {
      this.setState({
        searchState: state,
        lastLocation: searchStateToUrl(location, state),
      })
    }, DEBOUNCE_TIME) as unknown as number
  }
}

/**
 * Turn an Algolia search state into a relative URL for a hyperlink
 */
const createURL = (state: SearchState) => `?${qs.stringify(state)}${window.location.hash}`

/*
 * Create an absolute URL suitable for the history from the current location and search state.
 */
const searchStateToUrl = (location: Location, searchState: SearchState) => {
  if (!searchState) {
    return ''
  }

  searchState = {
    ...searchState,
  }
  // "configure" is not something that the user can toggle but is rather set by
  // search tabs.
  delete(searchState.configure)
  // since we use InfiniteSearchResults everywhere, having page doesn't help
  delete(searchState.page)

  // TODO: serialize this into the query string.  More complicated than it looks...
  delete(searchState.mapBounds)

  return new URL(createURL(searchState), location.toString()).toString()
}

/**
 * Parse the current search state out of a URL.
 */
const urlToSearchState = (location: Location) => {
  const searchState = qs.parse(location.search.slice(1))

  // TODO: use this from a connector instead of the dirty hack we've got going on
  // inside ListPane
  delete(searchState.selectedLocation)

  return searchState
}

function isSearchState(state: any): state is SearchState {
  return 'page' in state
}

export function isEmpty(obj: any) {
  if (!obj) { return true }

  for (const key of Object.keys(obj)) {
    const val = obj[key]
    if (val) {
      if (typeof(val) == 'string') {
        if (val.length > 0) {
          return false
        }
      } else {
        return false
      }
    }
  }

  return true
}
