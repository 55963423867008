import { IProvidedProps as IGoogleMapsProvided } from 'google-maps-react'
import * as React from 'react'

import connectGoogleMaps from '../../connectors/googleMaps'
import { connectMapRefinement, IMapRefinementProvided } from './connectors/connectMapRefinement'

type AutocompleteInputProps = React.InputHTMLAttributes<HTMLInputElement> &
  IMapRefinementProvided &
  IGoogleMapsProvided &
  {
    onPlaceChanged?: (place: google.maps.places.PlaceResult) => void,
  }

class AutocompleteInput extends React.Component<AutocompleteInputProps> {
  private inputRef: React.RefObject<HTMLInputElement>
  private autocomplete: google.maps.places.Autocomplete

  constructor(props: AutocompleteInput['props'], context?: any) {
    super(props, context)

    this.inputRef = React.createRef()
    this.placesChanged = this.placesChanged.bind(this)
  }

  public componentDidMount() {
    // https://developers.google.com/maps/documentation/javascript/places-autocomplete
    this.autocomplete = new this.props.google.maps.places.Autocomplete(
      this.inputRef.current,
      {
        types: ['(regions)'],
      },
    )
    this.autocomplete.addListener('place_changed', () => this.placesChanged())
  }

  public render() {
    const {
      // google maps provided
      google, loaded,
      // map refinement connector provided
      refine, createURL, currentRefinement,
      // our defined prop
      onPlaceChanged,
      ...inputProps } = this.props

    return <input {...inputProps} ref={this.inputRef}></input>
  }

  private placesChanged() {
    const place = this.autocomplete.getPlace()
    if (!place) { return }
    if (!place.geometry) { return }

    this.props.refine({
      selectedPlace: place,
      expandSearch: false,
    })
    if (this.props.onPlaceChanged) {
      this.props.onPlaceChanged(place)
    }
  }
}

const ConnectedAutocompleteInput = connectGoogleMaps(
  connectMapRefinement(AutocompleteInput),
)

export default ConnectedAutocompleteInput
