// Global utilities to be included on page
// This file gets imported by `packs/application.js`
//
// This is a manifest file that'll be compiled into the application pack, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.

import 'popper.js';
import 'bootstrap';

import emailCapture from './email-capture'
import focusLabel from './focus-label'

$(document).ready(() => {
  emailCapture()
  focusLabel()
})
