import * as React from 'react'
import { connectSearchBox } from 'react-instantsearch-core'

import ConnectedAutocompleteInput from './autocomplete'

interface IProps {
  refine: (query: string) => void
  currentRefinement: string
}

interface IState {
  searchText: string,
}

class SearchBox extends React.Component<IProps, IState> {

  constructor(props: IProps, context) {
    super(props, context)

    this.state = {
      searchText: props.currentRefinement,
    }

    this.submitSearch = this.submitSearch.bind(this)
    this.searchInputChanged = this.searchInputChanged.bind(this)
  }

  public render() {
    const {searchText} = this.state

    return <div className="ais-SearchBox">
      <form className="ais-SearchBox-form" action="" role="search" onSubmit={this.submitSearch}>
        <ConnectedAutocompleteInput type="search" className="ais-SearchBox-input"
          placeholder="City, State, Country, Church Name..."
          value={searchText}
          onChange={this.searchInputChanged}
          onPlaceChanged={this.placeChanged}>
        </ConnectedAutocompleteInput>
        <button type="submit" title="Submit your search query." className="ais-SearchBox-submit"></button>
      </form>
    </div>
  }

  private searchInputChanged(evt: React.ChangeEvent<HTMLInputElement>) {
    evt.preventDefault()

    this.setState({
      searchText: evt.target.value,
    })
  }

  private submitSearch(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault()
    this.props.refine(this.state.searchText)
  }

  private placeChanged = (place: google.maps.places.PlaceResult) => {
    this.props.refine('')
    this.setState({searchText: ''})
  }
}

export default connectSearchBox(
  SearchBox,
)
