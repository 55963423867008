import * as React from 'react'

import { Configure } from 'react-instantsearch-dom'
import AlgoliaSearch from '../algolia-search'
import ContentPane from './content-pane'
import MapPane from './map-pane'
import { ILocation } from './types'

interface ILocationMapProps {
  ministry_id: string
  indexName: string
}

interface IState {
  /** The search result that the user selected for viewing */
  selectedLocation?: ILocation
  includeUserLocationInBounds: boolean
}

const widgetName = 'location-map'

class LocationMap extends React.Component<ILocationMapProps, IState> {
  constructor(props) {
    super(props)

    this.state = {
      includeUserLocationInBounds: true,
    }
  }

  public render() {
    const { ministry_id, indexName } = this.props
    const {selectedLocation, includeUserLocationInBounds} = this.state
    const forcedFacets = `ministry:"${ministry_id}"`

    return (
      <AlgoliaSearch
            indexName={ indexName }
            widgetName={ widgetName + '-widget' }
            storeStateInQuery={true}
          >
        <div className={widgetName}>
          <Configure filters={forcedFacets} />
          <div className={ `${widgetName}__content` }>
            <ContentPane
              selectedLocation={ selectedLocation }
              onLocationSelect={ this.setLocation }
            />
          </div>
          <div className={ `${widgetName}__map` }>
              <MapPane
                selectedLocation={selectedLocation}
                includeUserLocationInBounds={includeUserLocationInBounds}
                onLocationSelect={(location) => this.setLocation(location)} />
          </div>
        </div>
      </AlgoliaSearch>
    )
  }

  public setLocation = (location: ILocation | null) => {
    if (location && this.state.selectedLocation &&
        location.objectID == this.state.selectedLocation.objectID) {
      // They may be trying to double-click the marker to zoom in further.
      // Reset the currentPlace so we don't limit the bounds
      this.setState({
        includeUserLocationInBounds: false,
      })
      return
    }

    this.setState({
      selectedLocation: location,
      includeUserLocationInBounds: true,
    })
  }

}

export default LocationMap
