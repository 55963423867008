import { GoogleAPI } from 'google-maps-react'
import * as React from 'react'

import connectGoogleMaps from '../../connectors/googleMaps'
import { connectMapRefinement, IMapCurrentRefinement } from './connectors/connectMapRefinement'
import { ILocation } from './types'
import { metersToMiles, renderDetails } from './utils'

interface IProps {
  google: GoogleAPI,

  hit: ILocation
  currentRefinement: IMapCurrentRefinement

  onLocationSelect: (location: ILocation) => void
}

interface IState {
  hovered: boolean
}

class ListItem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      hovered: false,
    }
  }

  public onMouseEntered() {
    this.setState({ hovered: true })
  }

  public onMouseLeft() {
    this.setState({ hovered: false })
  }

  public render() {
    const { hit } = this.props
    const { hovered } = this.state

    // again, ugly
    let iconHover = ''
    if (hovered) {
      iconHover = ' hovered'
    }

    const onClick = (e: any) => {
      e.preventDefault()
      this.props.onLocationSelect(hit)
    }

    return (
      <a className="churches-list__item__link" onClick={onClick}
        onMouseEnter={this.onMouseEntered.bind(this)} onMouseLeave={this.onMouseLeft.bind(this)}>
        <div className="churches-list__item__content">
          <div className="churches-list__item__content__details">
          { hit.trainingLocation && this.renderTrainingBadge() }
          { hit.has_spanish && this.renderSpanishBadge() }

          <h4 className="churches-list__item__content__title">
            { hit.title }
          </h4>

          <i className="material-icons">place</i>
          <span className="churches-list__item__content__text">
            { renderDetails(hit, this.calculateDistanceText()) }
          </span>
          </div>

          <div className={'churches-list__item__content__arrow' + iconHover}>
            <i className="material-icons">arrow_forward</i>
          </div>
        </div>
      </a>
    )
  }

  private renderTrainingBadge() {
    return <span className="churches-list__item__content__utility">
      <i className="icon ion-android-star"></i>
      Training Location
    </span>
  }

  private renderSpanishBadge() {
    return <span className="churches-list__item__content__utility">
      En Español
    </span>
  }

  private calculateDistanceText() {
    const { LatLng } =  this.props.google.maps
    const { computeDistanceBetween } = this.props.google.maps.geometry.spherical
    const {hit, currentRefinement} = this.props

    if (!currentRefinement.userLocation) {
      return ''
    }
    const {userLocation, selectedPlace} = currentRefinement

    const from = userLocation instanceof LatLng ?
    userLocation :
      new LatLng(userLocation.lat, userLocation.lng)
    const to = new LatLng(hit._geoloc.lat, hit._geoloc.lng)
    const meters = computeDistanceBetween(from, to)

    const km = meters / 1000
    const miles = metersToMiles(meters)
    const params = {
      miles: formatDistance(miles),
      km: formatDistance(km),
      place: undefined as string,
    }
    const key = ['locationMap', 'distance']
    if (selectedPlace) {
      params.place = selectedPlace.name
      key.push('aroundPlace')
    }
    if (miles < 1.0) {
      return window.I18n.t([...key, 'lessThan1'], params)
    }
    return window.I18n.t([...key, 'other'], params)
  }
}

export default connectGoogleMaps(
  connectMapRefinement(ListItem),
)

function formatDistance(value: number): string {
  if (value < 100) {
    return value.toFixed(1)
  }
  return value.toFixed(0)
}
