import { GoogleApiWrapper } from 'google-maps-react'
import WCC from 'wcc'

const apiKey = (WCC.CONSTANTS && WCC.CONSTANTS.GOOGLE_MAPS_API_KEY) ||
  process.env.GOOGLE_MAPS_API_KEY

const connectGoogleMaps = GoogleApiWrapper({
  apiKey,
  libraries: ['places', 'geometry'],
})

export default connectGoogleMaps
