import * as qs from 'qs'
import * as React from 'react'
import { connectStateResults, StateResultsProvided } from 'react-instantsearch-core'

import { connectMapRefinement } from './connectors/connectMapRefinement'
import { ConnectedExpandSearch } from './expand-search'
import LocationHits from './location-hits'
import SearchBox from './search-box'
import ConnectedStats from './stats'
import { ILocation } from './types'

interface IProps {
  selectedLocation?: ILocation
  hidden?: boolean

  onFilterToggle: (e: any) => void
  onLocationSelect: (location: ILocation) => void
}

class List extends React.Component<StateResultsProvided<ILocation> & IProps> {

  public componentWillReceiveProps(nextProps: Readonly<List['props']>) {
    const { searchResults, onLocationSelect, selectedLocation } = nextProps

    if (location.search) {
      const queryParams = qs.parse(location.search)
      if (queryParams && queryParams.selectedLocation) {
        if (selectedLocation && selectedLocation.objectID == queryParams.selectedLocation) {
          // we good
          return
        }
        // find and select the location from the search results that matches the selected location
        if (searchResults) {
          const loc = searchResults.hits.find((hit) => hit.objectID.toString() == queryParams.selectedLocation)
          if (loc) {
            onLocationSelect(loc)
          }
        }
      }
    }
  }

  public render() {
    const {
      onFilterToggle,
      onLocationSelect,
    } = this.props

    return (
      <div className={`location-map__pane ${this.props.hidden ? 'hidden' : ''}`}>
        <div className="location-map__pane__controls">
          <div className="searchbox">
            <i className="material-icons">search</i>
            <SearchBox />
          </div>
          <div onClick={onFilterToggle} className="text-button">
            <a>
              Filters
            </a>
            <i className="material-icons">arrow_drop_down</i>
          </div>
        </div>

        <div className="overflow-list">
          <ConnectedStats />
          <LocationHits onLocationSelect={onLocationSelect} />
          <ConnectedExpandSearch />
        </div>
      </div>
    )
  }
}

const PlacePill = connectMapRefinement((props) => {
  if (!props.currentRefinement || !props.currentRefinement.selectedPlace) {
    return null
  }

  const { selectedPlace } = props.currentRefinement

  return (
    <span className="button tiny pill primary-soft location-map__pane__place-pill"
      onClick={() => props.refine({
        selectedPlace: null,
        expandSearch: false,
      })}>
      {selectedPlace.name}
      <a className="ion-android-close close" style={{ paddingLeft: '2px' }}></a>
    </span>
  )
})

export default connectStateResults(List)
