import * as qs from 'qs'
import * as React from 'react'
import FilterPane from './filters'
import ListPane from './list'
import LocationPane from './location'
import { ILocation } from './types'

interface IProps {
  selectedLocation?: ILocation
  onLocationSelect: (location: ILocation) => void
}

interface IState {
  displayFilters: boolean
}

export default class extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)

    this.state = {
      displayFilters: false,
    }
  }

  public render() {

    return [
      <FilterPane key={'filter'} hidden={!this.filtersDisplayed()}
        onFilterToggle={ this.toggleFilters } />,
      <LocationPane key={'location'} hidden={!this.locationDisplayed()}
        onBackClick={ this.clearSelection }
        selectedLocation={ this.props.selectedLocation }
      />,
      <ListPane key={'list'} hidden={!this.listDisplayed()}
        onFilterToggle={ this.toggleFilters }
        onLocationSelect={ this.props.onLocationSelect }
        selectedLocation={ this.props.selectedLocation }
      />,
    ]
  }

  private clearSelection = (e) => {
    e.preventDefault()

    if (location.search) {
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
      if (queryParams && queryParams.selectedLocation) {
        delete(queryParams.selectedLocation)
        const current = new URL(location.toString())
        current.search = qs.stringify(queryParams, { addQueryPrefix: false })
        window.history.replaceState(
          {},
          document.title,
          current.toString(),
        )
      }
    }

    this.setState({
      displayFilters: false,
    })
    this.props.onLocationSelect(null)
  }

  private filtersDisplayed() {
    return !!this.state.displayFilters && !this.locationDisplayed()
  }

  private listDisplayed() {
    return !this.filtersDisplayed() && !this.locationDisplayed()
  }

  private locationDisplayed() {
    return !!this.props.selectedLocation
  }

  private toggleFilters = (e) => {
    e.preventDefault()
    this.setState({
      displayFilters: !this.state.displayFilters,
    })
  }
}
