import * as React from 'react'
import {
  connectCurrentRefinements,
  connectStats,
  CurrentRefinementsProvided,
  StatsProvided,
} from 'react-instantsearch-core'
import { connectMapRefinement, IMapRefinementProvided } from './connectors/connectMapRefinement'
import { Dayname, Daynames } from './types'
import { metersToMiles } from './utils'

declare module 'react-instantsearch-core' {
  // tslint:disable:interface-name
  interface StatsProvided {
    nbHits: number,
    processingTimeMS: number
  }

  export function connectStats(stateless: React.StatelessComponent<StatsProvided>): React.ComponentClass
  export function connectStats<TProps extends Partial<StatsProvided>, TDoc>(ctor: React.ComponentType<TProps>):
    ConnectedComponentClass<TProps, StatsProvided>

  // tslint:enable:interface-name
}

type Props = StatsProvided &
  IMapRefinementProvided &
  CurrentRefinementsProvided

class Stats extends React.Component<Props> {

  public render() {
    const {
      nbHits,
      processingTimeMS,
    } = this.props

    const text: string = window.I18n.t('locationMap.locations', {
      count: nbHits,
      seconds: processingTimeMS / 1000,
      place: this.placeText(),
      meetingDay: this.meetingDayText(),
      locationType: this.locationTypeText(),
    })

    return (
      <div className="ais-Stats">
        <span className="ais-Stats-text">
          {text}
        </span>
      </div>
    )
  }

  private placeText = () => {
    const { currentRefinement } = this.props

    if (!currentRefinement.selectedPlace) {
      return ''
    }

    if (currentRefinement.placeFacetValue) {
      return window.I18n.t(['locationMap', 'place', 'in'], {
        place: currentRefinement.placeFacetValue,
      })

    } else if (currentRefinement.maxSearchRadiusMeters) {
      const km = Math.floor(currentRefinement.maxSearchRadiusMeters / 10000) * 10
      const miles = Math.floor(metersToMiles(currentRefinement.maxSearchRadiusMeters) / 10) * 10

      return window.I18n.t(['locationMap', 'place', 'aroundWithMax'], {
        place: currentRefinement.selectedPlace.name,
        km,
        miles,
      })

    } else if (currentRefinement.expandSearch) {
      return window.I18n.t(['locationMap', 'place', 'distantFrom'], {
        place: currentRefinement.selectedPlace.name,
      })

    }  else {
      return window.I18n.t(['locationMap', 'place', 'around'], {
        place: currentRefinement.selectedPlace.name,
      })
    }
  }

  private locationTypeText = () => {
    const refinements = this.props.items

    const trainingLocation = refinements.find((r) => r.attribute == 'trainingLocation')
    const locationTypeKey = trainingLocation && trainingLocation.currentRefinement ?
      'trainingLocation' :
      'location'

    return window.I18n.t(['locationMap', 'locationType', locationTypeKey], { count: this.props.nbHits })
  }

  private meetingDayText = () => {
    const refinements = this.props.items
    const nbHits = this.props.nbHits

    let meetingDayText: string
    const meetingDay = refinements.find((r) => r.attribute == 'meetingDay')
    if (!meetingDay || !meetingDay.currentRefinement) {
      return ''
    }

    const meetingDays = (meetingDay.currentRefinement as Dayname[])
      .sort(byDayOfWeek)
      .map((day) => window.I18n.t(['locationMap', 'meetingDay', 'days_of_week', Daynames.indexOf(day) as any],
        { defaultValue: day }))

    const meetingDayCount = meetingDay.currentRefinement.length
    if (meetingDayCount > 2) {
      meetingDayText = meetingDays.slice(0, meetingDayCount - 1)
        .join(window.I18n.t('locationMap.meetingDay.words_connector'))
        + window.I18n.t('locationMap.meetingDay.last_word_connector')
        + meetingDays[meetingDayCount - 1]
    } else if (meetingDayCount == 2) {
      meetingDayText = meetingDays.join(window.I18n.t('locationMap.meetingDay.two_words_connector'))
    } else {
      meetingDayText = meetingDays[0]
    }
    return window.I18n.t('locationMap.meetingDay', { count: nbHits, days: meetingDayText })
  }
}

const ConnectedStats = connectMapRefinement(connectCurrentRefinements(connectStats(Stats)))
export default ConnectedStats

function byDayOfWeek(a: Dayname, b: Dayname): number {
  return Daynames.indexOf(a) - Daynames.indexOf(b)
}
