import { Map, Marker } from 'google-maps-react'
import { IMapProps } from 'google-maps-react'
import * as React from 'react'
import { connectHits, Hit } from 'react-instantsearch-core'

import connectGoogleMaps from '../../connectors/googleMaps'
import { connectCurrentMapBounds } from './connectors/connectCurrentMapBounds'
import mapStylesJSON from './map-style-config.json'
import { ILocation } from './types'

const mapStyles = mapStylesJSON
const centerUS: google.maps.LatLngLiteral = { lat: 39.82, lng: -96.1 }

export type MapContainerProps = IMapProps & {
  hits: Array<Hit<ILocation>>

  selectedLocation?: ILocation
  onLocationSelect: (location: ILocation) => void,
}

class MapContainer extends React.Component<MapContainerProps> {
  public static defaultProps: Partial<MapContainerProps> = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    initialCenter: centerUS,
    zoom: 4,
    maxZoom: 16,
    minZoom: 4,
    styles: mapStyles,
  }

  public render() {
    const {
      onLocationSelect,
      selectedLocation,
      bounds,
      ...props } = this.props

    const hits = this.props.hits.filter((x) => validGeoloc(x._geoloc))

    return (
      <Map { ...props }
        bounds={ bounds }
        onReady={ (_, map) => map.fitBounds(bounds) }>
        {this.renderMarkers(...hits)}
      </Map>
    )
  }

  private renderMarkers(...hits: Array<Hit<ILocation>>) {
    return hits.map((hit) => {
      return <Marker
        onClick={ () => this.props.onLocationSelect(hit) }
        cursor="pointer"
        position={ hit._geoloc }
        title={ hit.title }
        key={ hit.objectID }
        opacity={this.markerOpacity(hit)}
      />
    })
  }

  private markerOpacity = (hit: Hit<ILocation>) => {
    const {selectedLocation} = this.props
    if (selectedLocation) {
      return selectedLocation.objectID == hit.objectID ? 1.0 : 0.6
    }
    return 1.0
  }
}

export default connectGoogleMaps(
  connectCurrentMapBounds(
    connectHits(MapContainer),
  ),
)

function validGeoloc(geoloc: google.maps.LatLngLiteral): boolean {
  return geoloc && (geoloc.lat != 0 || geoloc.lng != 0)
}
