import { SearchParameters } from 'algoliasearch-helper'
import { createConnector, SearchResults, SearchState } from 'react-instantsearch-core'

import { isTooBig, milesToMeters, parsePosition } from '../utils'

const maxSearchRadiusMeters = Math.ceil(milesToMeters(50)) // 50 miles

export interface IMapRefinement {
  selectedPlace?: google.maps.places.PlaceResult
  includeUserLocationInBounds?: boolean
  expandSearch?: boolean,
}

export interface IMapCurrentRefinement extends IMapRefinement {
  userLocation: google.maps.LatLng | google.maps.LatLngLiteral,
  maxSearchRadiusMeters?: number
  placeFacetValue?: string
}

export interface IMapRefinementProvided {
  currentRefinement: IMapCurrentRefinement
  refine(newBounds: IMapRefinement): void
  createURL(newBounds: IMapRefinement): string
}

// tslint:disable-next-line:no-empty-interface
export interface IMapRefinementExposed {
}

// extend the Algolia search state
declare module 'react-instantsearch-core' {
  // tslint:disable-next-line: interface-name
  export interface SearchState {
    mapBounds?: IMapRefinement
  }
}

export const connectMapRefinement =
  createConnector<{ currentRefinement: IMapCurrentRefinement }, IMapRefinementExposed>({
  displayName: 'MapBoundsRefinement',

  getProvidedProps(
    props: IMapRefinementExposed,
    searchState: SearchState,
    searchResults: { results: SearchResults },
  ) {
    const currentRefinement = Object.assign({}, searchState.mapBounds) as IMapCurrentRefinement

    // Mimic the logic inside getSearchParameters, setting appropriate values on currentRefinement

    if (!hasMapRefinements(searchState)) {
      const results = searchResults && searchResults.results
      // no current location given, see if Algolia parsed it via our IP
      const userLocation = results && results.aroundLatLng && parsePosition(results.aroundLatLng)

      currentRefinement.userLocation = userLocation
      return { currentRefinement }
    }

    const {selectedPlace, expandSearch} = searchState.mapBounds
    currentRefinement.selectedPlace = selectedPlace

    if (expandSearch) {
      currentRefinement.userLocation = selectedPlace.geometry.location
      return { currentRefinement }
    } else if (isTooBig(selectedPlace)) {
      currentRefinement.placeFacetValue = selectedPlace.name
    } else {
      currentRefinement.userLocation = selectedPlace.geometry.location
      currentRefinement.maxSearchRadiusMeters = maxSearchRadiusMeters
    }

    return { currentRefinement }
  },

  refine(props: IMapRefinementExposed, searchState: SearchState, nextRefinement: IMapRefinement): SearchState {
    return {
      ...searchState,
      mapBounds: {
        ...searchState.mapBounds,
        ...nextRefinement,
      },
    }
  },

  getSearchParameters(
    searchParameters: SearchParameters,
    props: IMapRefinementExposed,
    searchState: SearchState,
  ) {
    if (!hasMapRefinements(searchState)) {
      // search around your current location as determined by Algolia
      return searchParameters
        .setQueryParameter('aroundLatLngViaIP', true)
        .setQueryParameter('aroundRadius', 'all')
    }

    const {selectedPlace, expandSearch} = searchState.mapBounds

    if (expandSearch) {
      // expand out from current location to all hits
      const loc = selectedPlace.geometry.location
      searchParameters = searchParameters
        .setQueryParameter('aroundLatLng', `${loc.lat()}, ${loc.lng()}`)
        .setQueryParameter('aroundRadius', 'all')
    } else if (isTooBig(selectedPlace)) {
      // search within facet of place name
      searchParameters = searchParameters.addDisjunctiveFacet('places')
        .addDisjunctiveFacetRefinement('places', selectedPlace.name)
    } else {
      // search in 50 mile radius from selected place (ex. a personal address)
      const loc = selectedPlace.geometry.location
      searchParameters = searchParameters
        .setQueryParameter('aroundLatLng', `${loc.lat()}, ${loc.lng()}`)
        .setQueryParameter('aroundRadius', maxSearchRadiusMeters)
    }

    return searchParameters
  },
})

function hasMapRefinements(searchState: SearchState): boolean {
  return !!(searchState.mapBounds && searchState.mapBounds.selectedPlace)
}
