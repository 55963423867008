import {
  AlgoliaSearch,
  HiddenFacets,
  IRefinementDefinition,
} from '@watermarkchurch/react-instantsearch-components'
import React, { Component } from 'react'
import { Configure } from 'react-instantsearch-dom'

import CheckboxRefinementList from '../checkbox-refinement-list'
import CustomRefinement from '../custom-refinement'
import MessageHits from './message-hits'

interface IProps {
  hitsPerPage: number
  title: string

  excludeID?: string
  hideRefinements?: boolean
  hiddenFacets?: IRefinementDefinition
  indexName?: string
  paginate?: boolean
}

class MessagesSearch extends Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    indexName: 'Messages_production',
    paginate: true,
  }

  public render() {
    const {
      hitsPerPage,
      title,
      paginate,
      hiddenFacets,
      indexName,
      hideRefinements,
      excludeID,
    } = this.props

    let refinements = <div className="messages-search__refinement-filters">
      <CustomRefinement
        attribute="media_types"
        title="Type"
        transformItems={(items) =>
          items.map((item) => ({
            ...item,
            label: this.itemLabel(item),
          }))
        }
        limit={999} />
      <CheckboxRefinementList
        attribute="topics" title="Topics" limit={999}
        aliasAttributes={{ primary_topic_short: 2 }} />
    </div>
    if (hideRefinements) {
      refinements = null
    }

    const configureOpts = {
      hitsPerPage,
      filters: excludeID ? `NOT objectID:${excludeID}` : '',
    }

    return (
      <div className="row justify-content-center">
        <div className="col-content-container">
          <AlgoliaSearch indexName={indexName} widgetName="messages-search" storeStateInQuery>
            <div className="row">
              <div className="col-12 messages-search__header">
                <Configure {...configureOpts} />
                {hiddenFacets &&
                  <HiddenFacets hiddenFacets={hiddenFacets} />}

                <div className="messages-search__title">
                  <h3 className="messages-search__title__text">{title}</h3>
                </div>
                {refinements}
              </div>
            </div>
            <MessageHits topicAttribute="topics" paginate={paginate} />
          </AlgoliaSearch>
        </div>
      </div>
    )
  }

  public itemLabel = (item) => {
    if (item.label == 'sermon_guide') {
      return 'article'
    }
    return item.label
  }
}

export default MessagesSearch
