import * as React from 'react'
import { ILocation } from './types'
import { renderAddress, renderMeetingTime, renderWebsite } from './utils'

interface IProps {
  hidden?: boolean
  selectedLocation?: ILocation
  onBackClick: (e: any) => void
}

export default class extends React.Component<IProps> {
  public render() {
    const location = this.props.selectedLocation

    if (!location) { return null }

    return (
      <div className={`location-map__pane ${this.props.hidden ? 'hidden' : ''}`}>

        <div className="location-map__pane__controls">
          <div className="back" onClick={ this.props.onBackClick }>
            <i className="material-icons">chevron_left</i> Back to All Locations
          </div>
        </div>

        <div className="location-details">

          <div className="row">
            <div className="col">
              <div className="location-details__group">
                <h3 className="location-details__title">{ location.title }</h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="location-details__meta">
                <i className="material-icons">access_time</i>
                <h6 className="label">Meeting Info</h6>
                <p>{renderMeetingTime(location)}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="location-details__meta">
                <i className="material-icons">place</i>
                <h6 className="label">Address</h6>
                <a href={ `https://www.google.com/maps/place/${ encodeURIComponent(renderAddress(location.address)) }` }
                  target="_blank">
                  { renderAddress(location.address) }
                </a>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="location-details__meta">
                <i className="material-icons">phone</i>
                <h6 className="label">Phone</h6>
                <a href={ `tel:${ location.contact.phone }` }>
                  { location.contact.phone }
                </a>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="location-details__meta">
                <i className="material-icons">laptop</i>
                <h6 className="label">Website</h6>
                <a href={ location.website } target="_blank">
                  { renderWebsite(location) }
                </a>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="location-details__meta">
                <i className="material-icons">email</i>
                <h6 className="label">Email</h6>
                <a href={ `mailto:${location.contact.email}` }>
                  { location.contact.email }
                </a>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="location-details__meta">
                <i className="material-icons">child_friendly</i>
                <h6 className="label">Has Childcare</h6>
                <span>{ location.hasChildcare ? 'Yes' : 'No' }</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="location-details__description">
                {/* Does this need a markdown renderer? */}
                <p>{ location.description }</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
