import {SearchParameters} from 'algoliasearch-helper'
import React from 'react'
import {
  ConnectedComponentClass,
  RefinementListProvided,
  SearchState,
} from 'react-instantsearch-core'
import { connectRefinementList } from 'react-instantsearch-dom'
import { present } from '../../utils'

// tslint:disable: max-line-length
// tslint:disable-next-line: interface-name
export interface ScoredFilterRefinementListExposed {
  /**
   * the primary attribute to filter on.  By default it has a score of 1 in the final query.
   * example: if attributeScores is empty, the filter will look like
   *   filters: "(attribute:selectedValue<score=1> OR attribute:selectedValue<score=1>)"
   */
  attribute: string

  /**
   * Scoring only makes sense when you want to weigh terms differently, which is achieved when using the OR operator.
   * https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/in-depth/filter-scoring/#scoring-ands-and-ors
   */
  operator?: 'or'

  /** allow search inside values */
  searchable?: boolean
  /** true if the component should display a button that will expand the number of items */
  showMore?: boolean
  /** the minimum number of displayed items */
  limit?: number
  /** the maximun number of displayed items. Only used when showMore is set to true */
  showMoreLimit?: number
  /**
   * the values of the items selected by default. The searchState of this widget takes the form of a list of strings,
   * which correspond to the values of all selected refinements. However, when there are no refinements selected,
   * the value of the searchState is an empty string.
   */
  defaultRefinement?: string[]
  /** (...args: any[]) => any to modify the items being displayed, e.g. for filtering or sorting them. Takes an items as parameter and expects it back in return. */
  transformItems?: (...args: any[]) => any

  /**
   * Given an attribute value, sets a score for that value.  Any values not provided
   * will default to 1.
   */
  scores?: { [value: string]: number } | ((value: string) => number | undefined)

  /**
   * Any other attributes that are an "alias" of this attribute, to which you want
   * to assign a multiplier for the score.
   * This is what we will use to prioritize matches on the "primary topic".
   * This will have { 'primary_topic': 2 } and the resulting filter will be:
   *  filters: "(topic:Anger<score=1> OR primary_topic:Anger<score=2>)"
   */
  aliasAttributes?: {
    [alias: string]: number | null,
  }
}
// tslint:enable: max-line-length

/**
 * connectScoredFilterRefinementList connector extends the logic provided by
 * connectRefinementList to allow you to build a RefinementList widget that scores
 * facet matches according to a scoring function.
 *
 * When a facet is selected, that facet will also be added to the "filters" parameter
 * with a score derived from the "scores" attribute of this widget.
 *
 * @example
 *   <ScoredFilterRefinementList
 *     attribute="company"
 *     scores={{ Google: 3, Amazon: 2 }} />
 *
 * When the facet values "Google", "Amazon", and "Facebook" are selected, the filter
 * will look like this:
 * `(company:Google<score=3> OR company:Amazon<score=2> OR company:Facebook<score=1>)`
 *
 * See https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/in-depth/filter-scoring/
 * for more details
 */
export function connectScoredFilterRefinementList<TProps extends Partial<RefinementListProvided>>(
  ctor: React.ComponentType<TProps>,
): ConnectedComponentClass<TProps, RefinementListProvided, ScoredFilterRefinementListExposed> {

  // we're going to do a bit of fun javascript meta-programming here -
  // let's create the RefinementListConnector wrapper class, and then extend it
  // at runtime to override setting the search parameters
  const RefinementListConnector = connectRefinementList(ctor)

  return class ScoredFilterRefinementListConnector extends RefinementListConnector {
    public getSearchParameters(
      searchParameters: SearchParameters,
    ): SearchParameters {
      const props = this.props as ScoredFilterRefinementListExposed
      const searchState = this.context.ais.store.getState().widgets as SearchState

      // first - call into the superclass to set facets on the RefinementList.
      // This allows the RefinementList logic to update SearchState currentRefinement
      // @ts-ignore - this method definitely exists but the typedefs don't include it
      searchParameters = super.getSearchParameters(searchParameters)

      const { attribute, scores, aliasAttributes } = props
      const scoringFn: (value: string) => number | undefined =
        typeof scores == 'function' ?
          scores :
          (value: string) => {
            return scores && scores[value]
          }

      const currentRefinement: string[] =
        searchState.refinementList && searchState.refinementList[attribute] || []

      const filters: string[] = []
      for (const value of currentRefinement) {
        const score = scoringFn(value) || 1
        filters.push(`${attribute}:${value}<score=${score}>`)
        if (aliasAttributes) {
          for (const alias of Object.keys(aliasAttributes)) {
            const multiplier = aliasAttributes[alias] || 1
            filters.push(`${alias}:${value}<score=${score * multiplier}>`)
          }
        }
      }

      const filterString = filters.filter(present).join(' OR ')
      if (filterString.length > 0) {
        searchParameters.filters = `(${filterString})`
      }

      return searchParameters
    }
  } as any
}
