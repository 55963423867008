import * as I18n from 'i18n-js'

import { Daynames, IAddress, ILocation } from './types'

export function parsePosition(aroundLatLng: string): google.maps.LatLngLiteral {
  if (!aroundLatLng) { return }

  const m = aroundLatLng.match(/^((?:-|\+)?[\d\.]+),\s*((?:-|\+)?[\d\.]+$)/i)
  if (!m) { return }
  return {
    lat: parseFloat(m[1]),
    lng: parseFloat(m[2]),
  }
}

const MilesPerMeter = 0.000621371192
export function metersToMiles(meters: number) {
  return meters * MilesPerMeter
}

export function milesToMeters(miles: number) {
  return miles / MilesPerMeter
}

const tooBigPlaces = [
  'country',
  'administrative_area_level_1', // state
  'administrative_area_level_2', // county
]

export function isTooBig(place: google.maps.places.PlaceResult): boolean {
  if (intersects(place.types, tooBigPlaces)) {
    return true
  }
  if (place.types.indexOf('locality')) {
    // some cities are bigger than others
    // super imprecise check, roughly equivalent to 25 miles either direction
    const span = place.geometry.viewport.toSpan()
    return span.lat() > 0.5 || span.lng() > 0.5
  }
  return false
}

function intersects(a: string[], b: string[]): boolean {
  for (const val of a) {
    if (b.indexOf(val) >= 0) {
      return true
    }
  }

  return false
}

export function renderAddress(address: IAddress) {
  return [
    address.street1, address.city, address.state, address.zip,
  ].join(', ')
}

export function renderShortAddress(address: IAddress) {
  return [
    address.city, address.state,
  ].join(', ')
}

export function renderMeetingTime(location: ILocation): string {
  const day = Daynames.indexOf(location.meetingDay)
  const parts = []
  if (day != -1) {
    const meetingDay =window.I18n.t(
      ['locationMap', 'meetingDay', 'days_of_week', day as any],
      { defaultValue: location.meetingDay },
    )
    parts.push(meetingDay)
  }

  if (location.meetingTime && location.meetingTime.length > 0) {
    parts.push(location.meetingTime)
  }
  // TODO: i18n.t(meetingTime)

  return parts.join(' @ ')
}

export function renderWebsite(location: ILocation): string {
  try {
    const url = new URL(location.website)
    return url.hostname
  } catch {
    // TODO: Can we send up a bugsnag or something here?
    return location.website
  }
}

export function renderDetails(location: ILocation, distance: string): string {
  const results = []
  results.push(renderShortAddress(location.address), distance, renderMeetingTime(location))
  return results.join(' \u2022 ')
}
