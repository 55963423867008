import * as React from 'react'
import { ClearRefinements, ToggleRefinement } from 'react-instantsearch/dom'
import WDayRefinement from './wday-refinement'

export default ({ hidden, onFilterToggle }) =>
  <div className={`location-map__pane ${hidden ? 'hidden' : ''}`}>
    <div className="location-map__pane__controls">
      <h4 className="location-map__pane__controls__title">Filter Results</h4>
      <div onClick={ onFilterToggle } className="text-button">
        <a>
          Hide Filters <i className="material-icons">arrow_drop_up</i>
        </a>
      </div>
    </div>

    <div className="location-map__pane__widget">
      <span className="location-map__pane__widget__slug">Meeting Day</span>
      <WDayRefinement attribute="meetingDay" defaultRefinement={[]} />
    </div>

    <div className="location-map__pane__widget">
      <span className="location-map__pane__widget__slug">Childcare Provided</span>
      <ToggleRefinement
        attribute="hasChildcare"
        value={ true }
        label=""
      />
    </div>

    <div className="location-map__pane__widget">
      <span className="location-map__pane__widget__slug">En Español</span>
      <ToggleRefinement
        attribute="has_spanish"
        value={ true }
        label=""
      />
    </div>

    <div className="location-map__pane__widget">
      <ClearRefinements translations={ { reset: 'Reset Filters' } } />
    </div>

  </div>
